.Form-Box{
    margin: auto;
    width: 350px;
}


@media screen and (max-width: 350px) { 
    .Form-Box{
        width: max-content;
    }
}